import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import get from "lodash/get"
import { useDispatch, useSelector } from "react-redux"

import FindStoreUtilNavView from "@/components/FindStoreUtilNav/v1/FindStoreUtilNav.view"
import { getTranslations } from "@/components/FindStoreUtilNav/v1/localization"
import { getMyLocation, getMyLocationByUserIP } from "@/utils/helper"
import {
  getStoreDataById,
  getStoreDataByDistance,
  getShortenedUrl,
  getUtilityStoreDetails,
} from "@/utils/location"
import {
  setStoreAddress,
  setUtilityStoreDetails,
  setCityLocation,
  setMyStore,
} from "@/store/features/locationSlice"
import { locationState } from "@/store/features/locationSlice"

import { getConfig } from "@/constants/config"

const FindStoreUtilNav = ({ show, active, eventHandler }) => {
  const dispatch = useDispatch()
  const { utilityStoreDetails,nearestUtilityData, utilityData, userCity, myStoreId } =
    useSelector(locationState)
    
  const initialState = {
    userCity: "",
    utilityLocation: "",
    utilityData: {},
    utilityStoreDetails: {},
    findStores: "",
    reverseGeo: "",
    myStoreId: localStorage.getItem("myStoreId") ?? 0,
    siteName: "",
    findStoreUrl: "",
    utilityStoreDetailsUrl: "",
  }
  const { t } = useTranslation("common")
  const router = useRouter()
  const [staticTexts, setStaticTexts] = useState({})
  const [state, setState] = useState(initialState)

  useEffect(() => {
    setStaticTexts(getTranslations(t))
    setInitalState()
  }, [])

  useEffect(() => {
    if (state.myStoreId !== 0) getUtilityDataById()
    else if (state.userCity !== "") getUtilityDataByDistance()
  }, [state.utilityLocation, state.userCity])

  useEffect(() => {
    setState(prevState => {
      if (prevState.myStoreId !== myStoreId) {
        localStorage.setItem("myStoreId", myStoreId)
        return {
          ...prevState,
          utilityStoreId: myStoreId,
        }
      } else return prevState
    })
    getUtilityDataById()
  }, [state.myStoreId, myStoreId])

  useEffect(() => {
    if (state.utilityStoreDetailsUrl != "") setUtilityStoreDetailsUrl()
  }, [state.utilityStoreDetailsUrl])

  const setInitalState = async () => {
    const config = await getConfig()
    getFindStoreUrl(config).then(url => {
      setState({
        ...state,
        findStores: get(config, "apiEndpoints.findStores", ""),
        reverseGeo: get(config, "apiEndpoints.reverseGeo", ""),
        siteName: get(config, "general.siteName", ""),
        utilityStoreDetailsUrl: get(config, "general.storeDetailsServletPath"),
        findStoreUrl: url,
      })
    })
  }
  const getFindStoreUrl = async config => {
    const findStoreUrl = await getShortenedUrl(
      get(config, "general.findStorePagePath", "")
    )
    return findStoreUrl
  }

  const setUtilityStoreDetailsUrl = async () => {
    const utilityStoreDetails = await getUtilityStoreDetails(state)
    dispatch(setUtilityStoreDetails(utilityStoreDetails))
    setState({
      ...state,
      utilityStoreDetails,
    })
  }
  const getUtilityDataByDistance = async () => {
    const utilityData = await getStoreDataByDistance(state)
    dispatch(setStoreAddress(utilityData))
    setState({
      ...state,
      utilityData,
    })
  }
  const getUtilityDataById = async () => {
    const utilityData = await getStoreDataById(state)
    dispatch(setStoreAddress(utilityData))
    setState({
      ...state,
      utilityData,
    })
  }
  const getUtilityGeolocation = (t = false) => {
    getMyLocation()
      .then(getLocation => {
        const details = {
          utilityLocation: getLocation.zipcode ?? "",
          userCity: getLocation.city ?? "",
        }
        dispatch(setCityLocation(details))
        setState({
          ...state,
          ...details,
        })
      })
      .catch(err => {
        getMyLocationByUserIP()
          .then(getLocation => {
            const details = {
              utilityLocation: getLocation.zipcode ?? "",
              userCity: getLocation.city ?? "",
            }
            dispatch(setCityLocation(details))
            setState({
              ...state,
              ...details,
            })
          })
          .catch(error => {
            console.log(error)
          })
      })
  }

  const handleUtilityStoreCheck = entity => {
    localStorage.setItem("myStoreId", entity)
    dispatch(setMyStore(entity))
    setState({
      ...state,
      myStoreId: entity,
    })
  }

  const redirect = zipCodeValue => {
    window?.location?.assign(
      `${window.location.origin + findStoreUrl + "?q=" + zipCodeValue}`
    )
  }
  const { findStoreUrl } = state
  if (!show) return null
  return (
    <FindStoreUtilNavView
      staticTexts={staticTexts}
      getUtilityGeolocation={getUtilityGeolocation}
      redirect={zipCodeValue => redirect(zipCodeValue)}
      redirectUrl={getShortenedUrl(findStoreUrl)}
      handleUtilityStoreCheck={entity => handleUtilityStoreCheck(entity)}
      active={active}
      utilityStoreDetails={utilityStoreDetails}
      utilityData={utilityData}
      nearestUtilityData = {nearestUtilityData}
      userCity={userCity}
      myStoreId={myStoreId === 0 ? initialState.myStoreId : myStoreId}
      eventHandler={eventHandler}
    />
  )
}

export default FindStoreUtilNav
