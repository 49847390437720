import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { isMobile } from "react-device-detect"
import { selectGenericState } from "@/store/features/genericSlice"
import HeaderNavTabs from "@/shared/HeaderNavTabs"
import Teaser from "@/components/Default/Teaser/v2/Teaser"

const Tab = props => {
  const {
    menu: { isOpen },
  } = useSelector(selectGenericState)
  const { data } = props
  const [selectedTab, setTab] = useState(isOpen && "")
  const [isHeaderTab, setisHeaderTab] = useState(false)
  const tabElement = useRef()
  const onClickTab = data => {
    props?.addHeaderNavTabAnalytics(data)
    if (selectedTab !== data["cq:panelTitle"]) {
      setTab(data["cq:panelTitle"])
    } else setTab("")
    const buttonStyle1 = document.querySelector(".button-format.cmp-container")
    if (buttonStyle1) {
      buttonStyle1.style.position = "unset"
      buttonStyle1.style.padding = "unset"
    }
    document
      .querySelector(
        ".ann-sacks-main-menu.ann-sacks-tab-bar >.cmp-container:first-child"
      )
    if (
      document.querySelector(".gbh-pro-menu__inner.icon-Close-Medium") ===
        null &&
      window.matchMedia("(max-width:1200px)").matches
    ) {
      document.getElementById("main-scroll-close").style.display = "none"
    }
  }
  useEffect(()=>{
    if(props.data.appliedCssClassNames==="ann-main-nav"){
      setisHeaderTab(true);
    }
  })
  useEffect(()=> {
    if( props.data.appliedCssClassNames === "vertical-tabs" && data[":itemsOrder"] && data[":itemsOrder"][0]){
      let tabData = data[":items"][data[":itemsOrder"][0]]
      if (selectedTab !== tabData["cq:panelTitle"]) {
        setTab(tabData["cq:panelTitle"])
      } else setTab("")
    }
      
  },[data])
  return (
    <div className={`tabs ${props.data.appliedCssClassNames ?? ""} ${isOpen ? "open" : ""} `}>
      <div className="cmp-tabs">
        <ol
          role="tablist"
          className="cmp-tabs__tablist"
          aria-multiselectable="false"
          ref={tabElement}
        >
          {data[":itemsOrder"] &&
            data[":itemsOrder"].map((key, index) => {
              const item = data[":items"][key]
              return (
                <li
                  role="tab"
                  className={`cmp-tabs__tab ${
                    selectedTab == item["cq:panelTitle"]
                      ? "cmp-tabs__tab--active"
                      : ""
                  }`}
                  tabIndex="0"
                  key={item["cq:panelTitle"]}
                  id={item["cq:panelTitle"]}
                  onClick={() => onClickTab(item)}
                >
                  {item["cq:panelTitle"]}
                </li>
              )
            })}
        </ol>
        {data[":itemsOrder"] &&
          (selectedTab?.length > 0 || !isMobile) &&
          data[":itemsOrder"].map((key, index) => {
            const item = data[":items"][key]
            return (
              <div
                role="tabpanel"
                className={`cmp-tabs__tabpanel ${
                  selectedTab == item["cq:panelTitle"]
                    ? "cmp-tabs__tabpanel--active"
                    : ""
                }`}
                aria-label={item["cq:panelTitle"]}
                key={`navItem-${index}`}
              >
                {isHeaderTab ?
                  <HeaderNavTabs
                    selectedTab={selectedTab}
                    setTab={setTab}
                    data={item}
                    isTabOpen={selectedTab == item["cq:panelTitle"]}
                    toggleMenu={() => {}}
                    eventHandler={{
                      store: {
                        isOpen,
                      },
                    }}/> : <Teaser data={item}/>
                  }
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Tab
