import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import Sticky from "react-sticky-el";
import LazyLoad from "react-lazyload";
import Sort from "./Sort";
import Filter from "./Filter";
import ProductTile from "../../ProductTile/v3";
import ProductsPerPage from "./ProductsPerPage";
import filterIcon from "@/public/icons/filter.svg";
import printIcon from "@/public/icons/print.svg";
import shareIcon from "@/public/icons/share.svg";
import backToTop from "@/public/icons/back-top-arrow.svg";
import gridOffIcon from "@/public/icons/grid-off.svg";
import gridOnIcon from "@/public/icons/grid-on.svg";
import { getShortenedUrlwithGeneral, sanitizeText } from "@/utils/helper";
import { isMobile, withOrientationChange, isTablet } from "react-device-detect";
import PromoBanner from "@/components/PromoBanner/v1/PromoBanner";
import SocialShare from "@/components/SocialShare/v1/SocialShare";
import Button from "@/components/core/Button/Button";
import useWindowResize  from "@/hooks/useWindowResize";
import { getConfig } from "@/constants/config"
import PLPLoader from "./PLPLoader";
import { aemAxiosInstance } from "@/constants/api";
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/index";
import { useRouter } from "next/router";
import { getPromoData } from "@/components/PromoBanner/v2/promoBannerHelper";

const ProductList = (props) => {
  const { presetConfigs, xfHtml = '' } = props;
  const [width] = useWindowResize();
  const router = useRouter()
  const { material } = router.query;
  document
    .querySelector("title")
    .setAttribute("aria-label", props.staticTexts.pageTitleAria);
  const { isLandscape, selectedFilterCount, facets, categoryName } = props;
  const [categoryData, setCategoryData] = useState([]);
  const [showFilter, setShowFilter] = useState(width >= 1024 ? true : false);
  const [navSticky, setNavSticky] = useState(false);
  const [promoInfo, setPromoInfo] = useState({
    imgUrl: "",
    desc: "",
    ctaText: "",
    ctaLink: "",
    position: "",
    promostyle: "",
  });
  const [rowCols, setRowCols] = useState(width < 1024 ? 1 : 3);
  const filterClass = cx({
    "product-list__filter": true,
    "product-list__filter--hide": !showFilter,
    "product-list__filter--modal": showFilter,
  });
  const filterButtonClass = cx({
    "product-list__filter-toggle-text": true,
    "product-list__filter-toggle-text--show": !showFilter,
  });
  const tileClass = cx({
    "product-list__tile-col": true,
    "product-list__tile-col--full-width": !showFilter,
  });

  const [showSocialLink, setShowSocialLink] = useState(false);
  const [swatchCount, setSwatchCount] = useState(width > 812 ? 7 : 5);
  const filterModalWindow = useRef(null);

  //calculate promo position
  const col =
    (width < 990 && (isMobile || isTablet)) || width < 480
      ? {
          "one-column": "col-12",
          "two-column": "col-12",
          "three-column": "col-12",
        }
      : {
          "one-column": "col promo-col",
          "two-column": "col-8 promo-col",
          "three-column": "col-12 promo-col",
        };
  const colVal = { "one-column": 1, "two-column": 2, "three-column": 3 };
  const row = isMobile
    ? props.promoPlacementMobile
    : props.promoPlacementDesktop;
  const position =
    isMobile && window.outerWidth < 1024
      ? rowCols === 1
        ? row - 1
        : row * rowCols - 2
      : row * rowCols - colVal[props.promoLayout];

  const executeScroll = () => {
    plpRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const plpRef = useRef(null);
  const [compareJsonPath,setCompareJsonPath]=useState("")
  const [general,setGeneral] = useState("")
  useEffect(() => {
    const configFn = async () => {
      const { general={} } = await getConfig()
      setGeneral(general)
      setCompareJsonPath(general?.compareJsonPath??"")
    }
    configFn()
  }, [])
  useEffect(() => {
    
    aemAxiosInstance({
      url: GLOBAL_CONFIGURATION_ENDPOINT,
      params: {
        path: compareJsonPath,
      },
    })
      .then((res) => { setCategoryData(res?.data?.data ?? [])})
      .catch((err) => {
        console.log("Failed to get compare attributes JSON file", err);
      });
  }, [compareJsonPath]);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setSwatchCount(width > 812 ? 7 : 5)
    );
    switch (true) {
      case width > 1440:
        setRowCols(3);
        break;
      case (width < 990 && (isMobile || isTablet)) || width < 1440:
        setRowCols(2);
        break;
      default:
        setRowCols(3);
    }

    return () => {
      window.removeEventListener("resize", () =>
        setSwatchCount(width > 812 ? 7 : 5)
      );
    };
  }, [isLandscape, width]);

  useEffect(() => {
    if (showFilter && (isMobile || isTablet || width < 990)) {
      filterModalWindow?.current?.focus();
    } else if (!showFilter && (isMobile || isTablet || width < 990)) {
      document.getElementById("showFilter")?.focus();
    }
  }, [showFilter]);

  useEffect(() => {
    props.sendPositionToParent && props.sendPositionToParent(position);
  }, [position]);

  useEffect(() => {
    getXfData();
  }, [xfHtml]);

  //BackToTop button focus to breadcrumb for ADA 
  const handleKeyUp = event => {
    if (event.keyCode === 13) {
      setTimeout(() => {
        const inactiveBread = document.querySelector(
          ".cmp-breadcrumb__item-link"
        )
        if (inactiveBread) {
          inactiveBread.focus() 
        }
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp)
    return () => {
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [])
  const setPromoJsonData = async (jsonData = {}) => {
    if (jsonData) {
      const shortenedCtaLink = getShortenedUrlwithGeneral(jsonData.ctaLink ?? "",general)||"/"
      const result = {
        imgUrl: jsonData.imageUrl ?? "",
        desc: `${jsonData.title ?? ""}`.replaceAll("&nbsp;", " "),
        ctaText: jsonData.ctaLinkText ?? "",
        ctaLink: shortenedCtaLink,
        ctaAriaLabel: `${jsonData.ctaLinkText ?? ""} ${sanitizeText(
          jsonData.title ?? ""
        )}`,
        assetPath: jsonData.assetPath ?? "",
        position: position,
        promostyle:
          jsonData.style && jsonData.style[0] ? jsonData.style[0] : "",
        contentPosition: jsonData.contentPosition,
        gradientPosition: jsonData.gradientPosition,
        mobileGradientPosition: jsonData.mobileGradientPosition,
        ctaLabel: jsonData.ctaLabel,
      };
      setPromoInfo(result)
      if (props.setPromoData) {
        props.setPromoData(result);
      }
    } else if (props.setPromoData) {
      props.setPromoData({ position });
    }
  }
  const getXfData = async () => {

    let link = props.xfHtml?.split(".html")[0];
      if (link) {
        try {
          const data = await getPromoData(link)
          setPromoJsonData(data)
        } catch (error) {
          console.error(error)
        }
      }
    
  }

  function handleKeyPress(e) {
    const focusElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusContent =
      filterModalWindow.current.querySelectorAll(focusElements);
    const firstElem = focusContent[0];
    const lastElem = focusContent[focusContent.length - 1].disabled
      ? focusContent[focusContent.length - 2]
      : focusContent[focusContent.length - 1];
    if (e.key === "Escape") {
      props.onModalClose()
      if (isMobile) {
        setShowFilter(false);
      }
      document.body.classList.remove("scroll-lock")
    }
    if (e.key === "Tab") {
      if (
        e.shiftKey &&
        (document.activeElement === firstElem ||
          document.activeElement === filterModalWindow.current)
      ) {
        e.preventDefault();
        lastElem.focus();
      }
      if (!e.shiftKey && document.activeElement === lastElem) {
        e.preventDefault();
        firstElem.focus();
      }
    }
  }

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSort = (sort) => {
    props.onSort(sort);
    executeScroll();
  };

  if (props.data?.length === 0) {
    return <PLPLoader />;
  }
  
  return (
    <div className="product-list" ref={plpRef} id="productListWrapperId">
      {props.isCurated && (
        <div className="container kf-react-container product-list__header product-list__curated">
          <div className="row">
            <div className="col-lg-8 col-xs-12">
              <h2
                aria-label={props.staticTexts.totalResults}
                className="product-list__total d-lg-block"
                tabIndex="0"
              >
                {props.totalResults > 0 &&
                  `${props.totalResults} ${props.staticTexts.results}`}
              </h2>
            </div>
          </div>
          <div className="row d-block d-lg-none">
            <div className="col">
              <div className="product-list__grid-control">
                <h2
                  aria-label={props.staticTexts.totalResults}
                  className="product-list__total"
                >
                  {props.totalResults} {props.staticTexts.results}
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* controls */}
      <Sticky
        onFixedToggle={() => setNavSticky(!navSticky)}
        className="product-listing__sticky-nav-wrap"
        stickyStyle={{ zIndex: 9, left: 0 }}
      >
        <div
          className={`container-fluid product-listing__sticky-nav ${
            navSticky && "product-listing__sticky-nav--shadow"
          }`}
        >
          <div className="product-listing__row">
            <div className="container kf-react-plp-container">
              <div className="product-listing__row">
                <div className="product-listing__col product-listing__controls">
                  <h2
                    aria-label={props.staticTexts.totalResults}
                    className="product-listing__total"
                  >
                    {props.totalResults} {props.staticTexts.results}
                  </h2>
                  <section className="sorting-section">
                    <span
                      className="product-listing__fillter_sort"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      {props.staticTexts.filterSort}
                    </span>
                    <button
                      id="showFilter"
                      aria-haspopup={isTablet || isMobile}
                      aria-expanded={isTablet || isMobile ? false : showFilter}
                      aria-label={props.staticTexts.filters}
                      className="product-listing__filter-toggle gbh-data-layer"
                      data-gbh-data-layer={
                        '{"eventAction":"plp ' +
                        (showFilter ? "show" : "hide") +
                        ' filter","eventName":"plp ' +
                        (showFilter ? "show" : "hide") +
                        ' filter","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
                        (showFilter ? "show" : "hide") +
                        ' filters","internalLinkPosition":"plp","internalLinkType":"plp:' +
                        (showFilter ? "show" : "hide") +
                        '","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}'
                      }
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      {width > 991 ? (
                        <ul className={filterButtonClass}>
                          <li className={showFilter ? "show" : "hide"}>
                            {props.staticTexts.hideFilter}
                          </li>
                          <li className={!showFilter ? "show" : "hide"}>
                            {props.staticTexts.showFilter}
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                      <img
                        src={filterIcon?.src}
                        className="product-listing__filter-toggle-icon"
                        alt="Toggle Filter"
                      />
                    </button>
                    {
                      <div className="product-listing__grid-control">
                        <div className="d-block d-sm-none">
                          <img
                            onClick={() => setRowCols(rowCols === 1 ? 2 : 1)}
                            className="product-listing__grid-control-icon"
                            src={
                              rowCols > 1 ? gridOnIcon?.src : gridOffIcon?.src
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    }
                    <Sort
                      tabIndex="0"
                      data={props.sorts}
                      selectedSort={props.selectedSort}
                      texts={props.staticTexts}
                      onChange={handleSort}
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
      {/* product-list */}
      <div className="container kf-react-container product-list__tiles">
        <div className="row">
          <div className="col">
            <div>
              {props.data?.length > 0 && (
                <div className={filterClass} aria-modal ref={filterModalWindow}>
                  <div
                    className={`product-list__filter--sticky ${
                      props.isCurated ? " product-list__filter-curated" : ""
                    }`}
                    aria-hidden={!showFilter}
                    onKeyDown={handleKeyPress}
                  >
                    {facets && (
                      <Filter
                        tabIndex="0"
                        texts={props.staticTexts}
                        {...props}
                        close={setShowFilter}
                        displayShare={
                          props.displayShare === "true" ? true : false
                        }
                        displayPrint={
                          props.displayPrint === "true" ? true : false
                        }
                        selectedFilterCount={selectedFilterCount}
                        handleScroll={executeScroll}
                        showFilter={showFilter}
                        addToUrl={true}
                        sort={props.sorts}
                        selectedSort={props.selectedSort}
                        onSortChange={handleSort}
                        showSort={width < 991}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className={tileClass}>
                <div className={`row row-cols-${rowCols}`}>
                  {props?.data?.map((product, i) => (
                    <React.Fragment key={i}>
                      <div
                        className={`col product-list__tile ${
                          props?.data?.length === 1 ? "product-single" : ""
                        }`}
                      >
                        <LazyLoad className="kf-lazyload-wrapper">
                          <ProductTile
                            rowCols={rowCols}
                            tabIndex="0"
                            loading={props.loading}
                            data={product}
                            mapImageToColor
                            texts={props.staticTexts}
                            lite={
                              !isLandscape
                                ? rowCols === 2
                                  ? true
                                  : false
                                : false
                            }
                            addToCompare={props.addToCompare}
                            removeFromCompare={props.removeFromCompare}
                            compareData={props.comparedProducts}
                            isEnable={props.isEnable}
                            defaultImage={props.defaultImageTag}
                            hoverImage={props.hoverImageTag}
                            currencySign={props.currencySign}
                            isGrouped={props.isGrouped}
                            swatchCount={swatchCount}
                            favorites={props.favorites}
                            currFilters={props.currFilters}
                            plpPage={true}
                            favPage={false}
                            index={i}
                            presetConfigs={presetConfigs}
                            categoryData={categoryData}
                          />
                        </LazyLoad>
                      </div>
                      {props?.xfHtml &&
                        (i + 1) % position === 0 &&
                        i < position && (
                          <div
                            className={`${
                              col[props?.promoLayout]
                            } product-list__tile`}
                          >
                            <PromoBanner
                              imageId={"promoImage" + i}
                              xfLink={props?.xfHtml}
                              col={colVal[props?.promoLayout]}
                              position={i}
                              promoInfo={promoInfo}
                              promoData={props?.promoData?.find(
                                itm => itm?.position === i
                              )}
                            />
                          </div>
                        )}
                    </React.Fragment>
                  ))}
                </div>
                <div
                  className="row gbh-data-layer"
                  data-gbh-data-layer='{"eventAction":"plp load more","eventName":"plp load more","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"load more","internalLinkPosition":"plp","internalLinkType":"plp:load more","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
                >
                  {props.totalResults > props.curRows &&
                    props.totalResults > props.data?.length && (
                      <Button
                        type="load-more"
                        role={"button"}
                        size="small"
                        customClass="product-list__load-more"
                        label={props.staticTexts.loadMore}
                        onClick={props.onLoadMore}
                        useDefaultClass={true}
                        ariaLabel={`${props.staticTexts.loadMore} ${props.categoryName} products`}
                      />
                    )}
                </div>
                <div className="row">
                  <div className="product-list__back-to-top">
                    {isMobile && (
                      <div className="product-list__print-and-share">
                        {props.displayPrint === "true" && (
                          <span role="button" onClick={() => window.print()}>
                            {props.staticTexts.print}
                            <img
                              src={printIcon?.src}
                              alt={props.staticTexts.print}
                            />
                          </span>
                        )}
                        {props.displayShare === "true" && (
                          <span
                            role="button"
                            onClick={() => setShowSocialLink(!showSocialLink)}
                          >
                            {props.staticTexts.share}
                            <img
                              src={shareIcon?.src}
                              alt={props.staticTexts.share}
                            />
                          </span>
                        )}
                        <SocialShare
                          showModal={showSocialLink}
                          id="kf-product-list"
                          onClose={() => setShowSocialLink(false)}
                        />
                      </div>
                    )}
                    {width > 1023 && (
                      <ProductsPerPage
                        pos="bottom"
                        curRows={props?.curRows + props?.start}
                        data={props.productsPerPage}
                        totalResults={props.totalResults}
                        texts={props.staticTexts}
                        onChange={props.onRows}
                        selectedTab="products"
                      />
                    )}
                    <button
                      role="link"
                      className="product-list__go-back gbh-data-layer"
                      data-gbh-data-layer='{"eventAction":"plp back to top","eventName":"plp back to top","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"back to top","internalLinkPosition":"plp","internalLinkType":"plp:navigation","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
                      onClick={handleBackToTop}
                    >
                      <span className="product-list__go-back-text">
                        {props.staticTexts.backToTop}{" "}
                      </span>
                      <img
                        className="product-list__go-back-image"
                        aria-hidden="true"
                        src={backToTop?.src}
                        alt={props.staticTexts.backToTop}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default withOrientationChange(ProductList);
