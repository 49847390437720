import React, { useState, useEffect } from "react";
import cx from "classnames";
import _get from "lodash/get";
import { NumericFormat as NumberFormat } from "react-number-format"
import Checkbox from "@/components/core/Checkbox/Checkbox";
import { getConfig } from "@/constants/config"
import { isTablet } from "react-device-detect";
import Tooltip from "@/components/core/ToolTip/ToolTip";
import SocialShare from "@/components/SocialShare/v1/SocialShare";
import { useDispatch, useSelector} from "react-redux";
import { addProduct, removeItems } from "@/store/features/favoritesSlice";
import { getShortenedUrlwithGeneral } from "@/utils/helper";
import _isEmpty from "lodash/isEmpty";
import {
  getUserPersona,
  getPDPUrl,
  showNewBadge,
  sanitizeTextForAnalytics,
  getCurrentSkuCouponDiscounts,
  debounce,
} from "@/utils/helper";
import swatchMissing from "@/public/images/swatch-missing.jpeg";

import useWindowResize  from "@/hooks/useWindowResize";
import { SWATCH_NA } from "@/constants";
import shareIcon from "@/public/icons/share.svg";
import wishIcon from "@/public/icons/wish.svg";
import filledWishIcon from "@/public/icons/wishlist-gray-filled.svg";
import { getPresetUrl } from "@/utils/index";
import { useTranslation } from "react-i18next";
import { onImageError } from "@/utils/index";
import { showToast, selectGenericState } from "@/store/features/genericSlice"
import { selectAuthState,setAuthModalVisibility } from "@/store/features/authSlice";
import { setAsProductDetails } from "@/store/features/asPdpSlice";


const ProductTile = (props) => {
  const {
    data,
    texts,
    lite,
    loading,
    addToCompare,
    removeFromCompare,
    compareData,
    highlightText,
    hideCompare,
    isEnable,
    currencySign,
    isGrouped,
    rowCols,
    presetConfigs = "{}",
    isPartsDiagramLinkVisible = false,
    categoryData = [],
  } = props;
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [onHover, setOnHover] = useState(false);
  const [hoverColor, setHoverColor] = useState("");

  const sku = data["sku_s"] ?? data["masterSKU_s"];
  const productCategory = _get(data, "Product_Category", "");
  const dispatch = useDispatch();
  const persona = getUserPersona();
  const colorCount = props.swatchCount ?? 7;
  const [width] = useWindowResize();
  const cartData = useSelector((state) => state.cart.data);

  const [selectedColor, setSelectedColor] = useState({});
  const [priceTag, setPriceTag] = useState("");
  const [general, setGeneral] = useState()
  const [tileUrl,setTileUrl] = useState()
  const [swatchUrl,setSwatchUrl]=useState("")
  const { general: { lwAppName = "" } = {} } = getConfig() || {};
  const [loginFavouriteCallback, setLoginFavouriteCallback] = useState(null)

  const { isAuth, access_token: accessToken } = useSelector(selectAuthState)
  const {toaster={}} = useSelector(selectGenericState)


  useEffect(() => {
    
    const configFn = async () => {

      const { general } = await getConfig()
      const {swatchUrl=""}=general??{}
      const finalSwatchUrl = swatchUrl?.toString().includes("PAWEB")?swatchUrl.replace("PAWEB", general?.scene7AccountName):swatchUrl
      setGeneral(general)
      setSwatchUrl(finalSwatchUrl)
    }
    configFn()
  }, [])
  useEffect(() => {
    if (loginFavouriteCallback && isAuth) {
      handleFavorite(loginFavouriteCallback)
    }
  }, [loginFavouriteCallback, isAuth])

  //check for product image
  let images = [];
  let productVariantImages = data["productImages.labelWithUrl_s"]
    ? [data["productImages.labelWithUrl_s"]]
    : data["productImages.labelWithUrl_ss"];
  let setDefaultProductImage = "";
  let setHoverProductImage = "";
  let isHover = true;
  const isSample = productCategory.toLowerCase() === "samples";

  if (productVariantImages?.length > 0) {
    let variantImages = productVariantImages.filter(
      (img) => sku === img.split("|")[0]
    );
    if (props.plpPage) {
      variantImages.find((img) => {
        let imgDetails = img.split("|");
        if (imgDetails[2] === props.defaultImage)
          return (setDefaultProductImage = imgDetails[1]);
        else setDefaultProductImage = variantImages[0].split("|")[1];
      });
      productVariantImages.find((img) => {
        let imgDetails = img.split("|");
        if (imgDetails[2] === props.hoverImage)
          return (setHoverProductImage = imgDetails[1]);
      });
    } else {
      productVariantImages.find((img) => {
        let imgDetails = img.split("|");
        if (sku === imgDetails[0])
          return (setDefaultProductImage = imgDetails[1]);
      });
    }

    images.push(setDefaultProductImage);
    if (props.plpPage) {
      if (setHoverProductImage === "") {
        isHover = false;
      }
      isHover === false
        ? images.push(setDefaultProductImage)
        : images.push(setHoverProductImage);
    }
  } else {
    images = ["MissingImage"];
  }
  // get variant image if color filters are applied
  let selColor = "";
  let variantPrice = 0;
  let variantDiscPrice = 0;
  if (props.mapImageToColor && props.currFilters) {
    const colorFilter = props.currFilters.find(
      (q) =>
        q.facet === "Color.Finish" ||
        q.facet === "Color" ||
        q.facet === "Finish"
    );
    const selColors = colorFilter?.value;
    if (selColors?.length > 0) {
      let selectedProductImage = "";
      let i = selColors?.length - 1 ?? 0;
      while (!selectedProductImage && i >= 0) {
        const colorName = selColors[i];
        if (data[`Color.${persona}.Details_ss`]) {
          const exactSKUDetails =
            data[`Color.${persona}.Details_ss`]
              .find((item) => item.split("|")[1] === colorName)
              ?.split("|") ?? [];
          selColor = selColors[i];
          const productId =
            exactSKUDetails[5]?.split("?")[0].split("/").slice(-1) ?? [];
          selectedProductImage = productId[0] ?? "";
          variantPrice = exactSKUDetails[2] ?? 0;
          variantDiscPrice = exactSKUDetails[3] ?? 0;
        }
        i--;
      }

      if (!selectedProductImage) selectedProductImage = "MissingImage";
      if (images[0] === images[1]) {
        images = [selectedProductImage, selectedProductImage];
      } else {
        images[0] = selectedProductImage;
      }
    }
  }

  let colors = [];
  if (data["Color.SKU.Details_ss"]) {
    colors = data["Color.SKU.Details_ss"].map((color) => {
      const d = color.split("|");
      return {
        name: d[0],
        swatch: d[1],
        sku: d[2],
        isStock: d[3],
        isDiscontinued: d[4],
        customerFacingSKU: d[5] ? d[5] : d[2],
      };
    });
  } else if (data["Color.SKU.Details_s"]) {
    const d = data["Color.SKU.Details_s"].split("|");
    colors = [
      {
        name: d[0],
        swatch: d[1],
        sku: d[2],
        isStock: d[3],
        isDiscontinued: d[4],
        customerFacingSKU: d[5],
      },
    ];
  } else if (data["SKU.Details_ss"]) {
    colors = data["SKU.Details_ss"].map((color) => {
      const d = color.split("|");
      return {
        name: "",
        swatch: SWATCH_NA,
        sku: d[0],
        isStock: d[1],
        isDiscontinued: d[2],
        customerFacingSKU: d[3],
      };
    });
  }

  let selSku = "";
  let firstSwatchColor = "";
  if (props.mapImageToColor && selColor) {
    selSku = colors.find((item) => item.name === selColor)?.sku ?? "";
  } else {
    selSku = sku;
    firstSwatchColor = sku;
  }

  if (hoverColor) {
    selSku = hoverColor;
    const skuDetails =
      data[`Color.${persona}.Details_ss`]
        ?.find((item) => item.split("|")[0] === selSku?.sku)
        ?.split("|") ?? [];
    const productId = skuDetails[5]?.split("?")[0].split("/").slice(-1) ?? [];
    const selProdImage = productId[0] ?? "MissingImage";
    if (images[0] === images[1]) {
      images = [selProdImage, selProdImage];
    } else {
      images[0] = selProdImage;
    }
    variantPrice = skuDetails[2] ?? 0;
    variantDiscPrice = skuDetails[3] ?? 0;
  }

  let InstallationWithoutSPPDFResource =
    data["ProductResource.PartsPDFFileName.resourceFullWebURL_ss"];

  useEffect(() => {
    window.wow?.sync();
  }, []);

  useEffect(() => {
    const compareSku =
      selSku !== "" && (typeof selSku === "string" ? selSku : selSku?.sku);
    if (
      compareData?.comparedProducts.find(
        (prod) => prod.compareSku === compareSku
      )
    )
      setIsChecked(true);
    else setIsChecked(false);
  }, [compareData?.comparedProducts, data]);

  colors = colors.filter(
    (item) =>
      item.isDiscontinued === "false" ||
      Date.parse(item.isDiscontinued) > Date.now()
  );

  const getProductName = (isFavPage = true) => {
    // Used different fl for fetching the product names because for plp and favorite pages,
    // different api is being called i.e. for plp search/plp and for favorites search/full-catalog
    let productName;

    if (isFavPage) {
      productName = isGrouped
        ? _get(data, "Collapse_Title_s", "")
        : props.type === "parts"
        ? _get(data, "productName_s", "")
        : _get(data, "RegionBrandName_s", "") ||
          _get(data, "Product_Title_s", "");
    } else {
      productName = isGrouped
        ? _get(data, "Collapse_Title_s", "")
        : props.type === "parts"
        ? _get(data, "productName_s", "")
        : _get(data, "Non_Collapse_Title_s", "");
    }
    return productName;
  };
  useEffect(() => {
    setHoverColor("");
    setSelectedColor("");
  }, [props.currFilters, props.selectedSort]);

  const tileTitle = _get(data, "Tile_Title", "");
  const productName = getProductName(props?.favPage);
  const description = isGrouped
    ? _get(data, "Collapse_Description_s", "")
    : _get(data, "ProductDescriptionProductShort_s", "");
  const category = data.Product_Category
    ? data.Product_Category
    : data.ProductLocalCategory_s ?? "";
  const shipmentDate = _get(data, "RegionReleaseforShipment_dt", "");
  const isNewProduct = showNewBadge(shipmentDate);
  const badgeVal = _get(data, `priceList.${persona}.saleOffer_s`, "");
  let discountedPrice = _get(data, `priceList.${persona}.finalPrice_d`, 0);
  let finalPrice = _get(data, `priceList.${persona}.price_d`, 0);
  const price = _get(data, `priceStartingAt.${persona}_d`, 0);


  const exclusiveBadge = _get(data, "ProductExclusive_s", "");
  const isFavorite = props.favorites?.find(
    (vl) => vl.productId === data["ctId_s"]
  )
    ? true
    : false;
  const isDiscountedPrice = _get(data, `priceList.${persona}.discountedPrice_d`, 0);
  const detaultSelectedColor = colors.length ? colors[0]?.name : "";
  const defaultCustomerFacingSku = colors.length
    ? colors[0]?.customerFacingSKU
    : "";
  const defaultImage = images.length ? images[0] : "";
  const defaultProductIsInStock = colors.length
    ? colors[0].isStock === "true" || colors[0].isStock === true
    : false;
  const discountPercent =
    badgeVal && isDiscountedPrice
      ? 100 - Math.round((isDiscountedPrice / price) * 100)
      : 0;
  const discountPriceState =
    badgeVal && isDiscountedPrice
      ? "percentOff|" + discountPercent
      : "regular price";
  const productIsRetail =
    _get(data, "ProductIsRetail", "") === "true" ? true : false;
  const isSuperSku = _get(data, "comboSKUAvailable_b", null) ? true : false;
  const discountAmount = discountPercent
    ? Number((price - isDiscountedPrice).toFixed(2))
    : 0;
  const productSaleable = productIsRetail || !colors.length ? false : true;
  const productRoom = _get(data, "category1_s", "n/a");
  const productCollectionsName = _get(data, "ProductBrandNameDisplay_s", "n/a");
  let maxPrice = 0;
  let discountedMaxPrice = 0;

  if (isGrouped) {
    finalPrice = _get(data, `priceStartingAt.${persona}_d`, 0);
    maxPrice = _get(data, `max_price`, 0);
    discountedPrice = _get(data, `discountedPriceStartingAt.${persona}_d`, 0);
    discountedMaxPrice = _get(data, `disc_max_price`, 0);
  } else if (
    props.mapImageToColor &&
    (selColor || hoverColor) &&
    variantPrice > 0
  ) {
    finalPrice = variantPrice;
    discountedPrice = variantDiscPrice;
  } else if (props.exactSKU) {
    const exactSKUDetails = data[`Color.${persona}.Details_ss`]
      ? data[`Color.${persona}.Details_ss`]
          .find((item) => item.split("|")[0] === sku)
          .split("|")
      : [];
    finalPrice = exactSKUDetails[2] ?? 0;
    discountedPrice = exactSKUDetails[3] ?? 0;
  }
  const getTempType = (str = "") => {
    if (
      str.toLowerCase()?.includes("tile") ||
      str.toLowerCase()?.includes("mosaic")
    ) {
      setPriceTag(`${texts.perSF}`);
    } else if (str.toLowerCase()?.includes("trim")) {
      setPriceTag(`${texts.perLF}`);
    } else {
      setPriceTag("");
    }
  };

  useEffect(() => {
    getTempType(productCategory);
  });
  useEffect(() => {
    
    const getPDPUrlFn = async () => {

      const tileUrlVal = await getPDPUrl(category, data.slug_s)
      setTileUrl(tileUrlVal)
    }
    getPDPUrlFn()
  }, [])
  if (loading)
    return (
      <div className="product-tile--loading">
        <div className="loading-image shimmer"></div>
        <div className="loading-title shimmer"></div>
        <div className="loading-description shimmer"></div>
        <ul className="loading-swatch">
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
        </ul>
      </div>
    );

  const colorSwatchDefaultImg = (e) => {
    e.target.src = swatchMissing?.src;
  };

  const isCategoryMatchWithCompareJSON = categoryData?.some(
    (item) => item?.category === productCategory
  );

  const handleCompare = (event, sku) => {
    setIsChecked(!isChecked);
    if (event.target.checked) {
      if (compareData.comparedProducts.length < 4) {
        let variantFlag = false;
        const allVariantSkus = compareData.comparedProducts
          ?.map((a) => [...a.variants, a.masterVariant])
          .flat()
          ?.map((a) => a.sku);
        if (allVariantSkus?.includes(sku)) {
          variantFlag = true;
        }
        if (!isCategoryMatchWithCompareJSON) {
          setErrorMessage(texts?.sameVariants);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
          setIsChecked(false);
          removeFromCompare(sku);
        } else if (
          compareData.category !== "" &&
          compareData.category !== productCategory
        ) {
          setErrorMessage(texts.sameCategory);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
          setIsChecked(false);
          removeFromCompare(sku);
        } else if (variantFlag) {
          setErrorMessage(texts?.sameVariants);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
          setIsChecked(false);
          removeFromCompare(sku);
        } else {
          addToCompare(sku, data?.RegionOnBackOrder_s ?? false);
        }
      } else {
        setErrorMessage(texts.maxProduct);
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
        setIsChecked(false);
        removeFromCompare(sku);
      }
    } else {
      removeFromCompare(sku);
    }
    const eventInfo = JSON.parse(getEventInfo("compare"))
    const productInfo = JSON.parse(getProductInfo())?.productInfo
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page: window?.adobeDataLayer?.getState?.("page"),
      productInfo
    })
  };

  const handleLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    window.loginFavouriteCallback = () => {
      setLoginFavouriteCallback(e)
    }
    dispatch(setAuthModalVisibility({ show: true }))
  }
  const handleFavorite = e => {
    
    e.preventDefault()
    e.stopPropagation()
    window.preActiveElement = e.target
    if (isFavorite === true) {
      
      props.favorites?.map(fav => {
        if (fav.productId === data["ctId_s"]) {
          if (!isAuth) {
            handleLogin(e)
          } else {
            
            dispatch(removeItems({ ids: [fav.id], isDataLayerNeeded: false }))
              .unwrap()
              .then(() => {
                handleCallBack("success", texts.removeItem)
                dispatch(
                  showToast({
                    message: getShortenedUrlwithGeneral(toaster?.content?.unfavoriteSuccessMessage, general) || texts.removeItem,
                    isVisible: true,
                  })
                )
              })
              .catch(err => {
                dispatch(
                  showToast({
                    message: texts.removeItemError,
                    isVisible: true,
                  })
                )
                handleCallBack("failure", texts.removeItemError)
              })
          }
        }
      })
    } else {
      if (!isAuth) {
        handleLogin(e)
      } else {
        
        dispatch( addProduct( sku ))
          .unwrap()
          .then(() => {
            handleCallBack("success", texts.favSuccessMsg)
            dispatch(
              showToast({
                message: getShortenedUrlwithGeneral(toaster?.content?.successMessage, general) || texts.favSuccessMsg,
                isVisible: true,
              })
            )
          })
          .catch(err => {
            dispatch(
              showToast({
                message: texts.favErrorMsg,
                isVisible: true,
              })
            )
            handleCallBack("failure", texts.favErrorMsg)
          })
      }
    }
  }
  const handleCallBack = (status, message) => {
    const { adobeDataLayer: { getState } = {} } = window;
    const page = (getState && getState("page")) || {};
    let isForSample = false;
    if (page && page?.category?.subCategory1) {
      const {
        category: { subCategory1 },
      } = page;
      isForSample = subCategory1.includes("samples");
    }
    const eventInfo = getEventInfo("favorite", null, true, isForSample);
    const productInfo = getProductInfo(null, true);
    eventInfo.eventStatus = status;
    eventInfo.eventMsg = sanitizeTextForAnalytics(message);

    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    });
  };

  const handleShare = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowShare(true);
  };

  const badgeTxt = isNewProduct
    ? exclusiveBadge
      ? texts.newExclusive
      : texts.new
    : exclusiveBadge
    ? texts.exclusive
    : "";
  const pageRedirectUrl =
    props.type?.toLowerCase() === "parts"
      ? `${tileUrl}${
          selSku
            ? "&skuId=" + (typeof selSku === "string" ? selSku : selSku?.sku)
            : "&skuId=" + sku
        }`
      : `${tileUrl}${
          selSku
            ? "?skuId=" + (typeof selSku === "string" ? selSku : selSku?.sku)
            : ""
        }`;

  const redirectPDP = (e) => {
    e.preventDefault();
    e.stopPropagation();
    location.href = decodeURIComponent(pageRedirectUrl);
  };

  const setHover = () => {
    document
      .getElementById(`kf-product-tile-wrapper-${data["ctId_s"]}`)
      .classList.add("--hover");
    setOnHover(true);
  };
  const removeHover = () => {
    document
      .getElementById(`kf-product-tile-wrapper-${data["ctId_s"]}`)
      .classList.remove("--hover");
    setOnHover(false);
  };

  const presetConf = JSON.parse(presetConfigs);

  const getEventInfo = (
    type,
    color,
    needsJSON = false,
    isForSample = false
  ) => {
    /**
     * type is default navigation, could be favorite or share
     * color is optional, could be selected color from plp or search page product
     * needsJSON returns either JSON or stringified value
     */

    const isPartsDiagram = type === "parts diagram";
    const alternameType =
      type === "favorite"
        ? isFavorite
          ? "favorite remove"
          : "favorite add"
        : type === "compare"
        ? isChecked
          ? "compare remove"
          : "compare add"
        : "";
    const compareInternalLinkName =
      type === "compare"
        ? isChecked
          ? "remove compare"
          : "add to compare"
        : "";
    const pageSection = props.favPage && props.selectedtab ? "favorites" : "";
    const selectedTab =
      (props.favPage && props.selectedtab) ?? props?.selectedtab?.toLowerCase();
    const plpPrefix = isForSample ? "plp:sample" : "plp";
    const selectedTabForSearchResults =
      props?.type?.toLowerCase() || "products";

    const colorSelection = color
      ? "color selection"
      : alternameType ||
        sanitizeTextForAnalytics(type || productName + " " + description);
    const eventInfo = {
      eventAction: `${
        pageSection
          ? "my account:" + pageSection + ":"
          : (props.plpPage ? plpPrefix : "search results") +
            (isForSample ? ":" : " ")
      }${isPartsDiagram ? "products:" : "product "}${
        alternameType || type || "navigation"
      }`,
      eventName: `${
        pageSection
          ? "my account:" + pageSection + ":"
          : (props.plpPage ? plpPrefix : "search results") +
            (isForSample ? ":" : " ")
      }${isPartsDiagram ? "products:" : "product "}${
        alternameType || type || "navigation"
      }`,
      eventType:
        isForSample || type === "favorite"
          ? isPartsDiagram
            ? "navigation"
            : "wishlist save"
          : "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName:
        type === "compare" ? compareInternalLinkName : colorSelection,
      internalLinkPosition: selectedTab
        ? selectedTab
        : props.plpPage
        ? plpPrefix
        : "search results",
      internalLinkType: pageSection
        ? "my accounts:navigation"
        : `${props.plpPage ? plpPrefix : "search results"}:${
            type || (props.plpPage ? "navigation" : "product tiles")
          }`,
      internalLinkZoneName: selectedTab
        ? `my account:${pageSection}:${selectedTab}:main content area`
        : props.plpPage
        ? `${plpPrefix}:promo tiles`
        : `search results:${selectedTabForSearchResults}:main content area`,
      internalLinkURL: !type
        ? (tileUrl?.indexOf("http") === -1 ? window.location.origin : "") +
          tileUrl +
          (color ? "?skuId=" + color.sku : selSku ? "?skuId=" + selSku : "")
        : "n/a",
      clickInternalLinks: "true",
    };

    if (needsJSON) {
      eventInfo.custom = getProductInfo();
      return eventInfo;
    }
    return JSON.stringify(eventInfo);
  };

  const getProductInfo = (color, needsJSON = false) => {
    const { productCouponCode, productCoupondiscount } =
      getCurrentSkuCouponDiscounts({ cart: cartData, sku: sku });
    const productInfo = {
      productInfo: {
        productBrand: general?.siteName?.toLowerCase(),
        description: sanitizeTextForAnalytics(description) || "n/a",
        globalPromotionPrice:
          discountPercent && discountAmount
            ? Number((price - discountAmount).toFixed(2))
            : price,
        priceState: discountPriceState,
        productBasePrice: price,
        productCategory: sanitizeTextForAnalytics(category) || "n/a",
        productColor:
          sanitizeTextForAnalytics(color ? color.name : detaultSelectedColor) ||
          "n/a",
        productID:
          (color ? color.customerFacingSKU : defaultCustomerFacingSku) || "n/a",
        productName: sanitizeTextForAnalytics(productName) || "n/a",
        productRoom: sanitizeTextForAnalytics(productRoom || category) || "n/a",
        productSalePrice: discountedPrice ? discountedPrice : price,
        productSaleable: productSaleable,
        productStatus: color
          ? color.isStock === "true" || color.isStock === true
            ? "in stock"
            : "out of stock"
          : defaultProductIsInStock
          ? "in stock"
          : "out of stock",
        productSuperSku: isSuperSku,
        productTileName: sanitizeTextForAnalytics(tileTitle) || "n/a",
        defaultImageName: defaultImage || "n/a",
        productCollectionsName:
          sanitizeTextForAnalytics(productCollectionsName || category) || "n/a",
        globalOffer: badgeVal ? badgeVal : "n/a",
        globalDiscount: discountPercent ? discountAmount : "n/a",
        productPartnerBuyNow: productIsRetail,
        pdpType: "regular finished goods & service parts",
        productFindingMethod: "n/a",
        productCouponCode: productCouponCode.join("|") || "n/a",
        productCoupondiscount: productCoupondiscount,
        frequency: "n/a",
        isRecommended: "n/a",
        isSendNow: "n/a",
        isSubscription: "n/a",
        quantity: "n/a",
        ratings: "n/a",
        numberOfReviews: "n/a",
      },
    };
    if (needsJSON) {
      return productInfo.productInfo;
    }
    return JSON.stringify(productInfo);
  };

  const onSelectedColor = (e, sku, customerFacingSKU) => {
    e.stopPropagation();
    setSelectedColor({ sku: sku, customerFacingSKU: customerFacingSKU });
    setSelected({ sku: sku, customerFacingSKU: customerFacingSKU });
  };

  const checkColorFocus = (e, colors, index) => {
    const colorSlice = colors.slice(0, colorCount);
    if (
      (e.key === "Tab" && colorSlice.length - 1 === index) ||
      (e.shiftKey && e.key === "Tab" && index === 0)
    ) {
      setHoverColor(selectedColor ?? "");
    }
  };

  const handleOnClickPartsDiagramLink = (event) => {
    event.preventDefault();
    props.handleOnClickPartsDiagramLink(data);
  };
  const getClickSignalPayload = () => {
    const userInfo =
      window.adobeDataLayer.getState("page.user.authStatus") === "anonymous"
        ? "guest"
        : window.adobeDataLayer.getState("page.user.commerceToolsID");
    const sTimeStamp =
      new Date().toISOString().split("T")[0] +
      "T" +
      new Date().toTimeString().split(" ")[0] +
      "Z";
    return [
      {
        params: {
          query: highlightText ?? productCategory,
          app_id: general.siteName.toLowerCase(),
          doc_id: props.data.id,
          fusion_query_id: props.fusionQueryId,
          ctype: props.parent,
          user_id: userInfo,
          country: "USA",
          region: "Northeast",
          item_pos: props.index,
          plc: productCategory,
          sku_id: props.data.sku_s,
          session_id: props?.sessionId,
          count_i: "1",
          url: window.location,
        },
        id: `${new Date().valueOf()}_${userInfo}_${sku} `,
        timestamp: sTimeStamp,

        type: "click",
      },
    ];
  };
  const triggerClickSignal = async () => {
    const aSignalsPayload = getClickSignalPayload();

    await apim.post(
      `/search/signals/${general.siteName.toLowerCase()}`,
      aSignalsPayload
    );
  };

  const serviceParts = t("kf.search.serviceparts");
  const productTileClickHandler = () => {
    const { adobeDataLayer: { getState } = {} } = window;
    const page = (getState && getState("page")) || {};
    const eventInfo = getEventInfo("", null, true);
    const productInfo = getProductInfo(null, true);
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    });
  };

  let asProductDetailActionPayload = {}
  if(data){
    asProductDetailActionPayload = {
      sku:data.sku_s ?? " ",
      slug:data.slug_s ?? " ",
      response:data
    }

  }

  return (
    <div
      id={`kf-product-tile-wrapper-${data["ctId_s"]}`}
      className="product-tile__wrapper wow animate__fadeInUp"
      onFocus={setHover}
      onBlur={removeHover}
      onMouseLeave={() => setShowShare(false)}
      onMouseEnter={()=>{dispatch(setAsProductDetails(asProductDetailActionPayload))}}
    >
      <SocialShare
        showModal={showShare}
        id={`kf-plp-tile-${data["ctId_s"]}`}
        url={window.location.origin + tileUrl}
        onClose={() => setShowShare(false)}
        analyticData={getEventInfo("share", null, true)}
      />
      {lwAppName.toLowerCase() === "annsacks" ? (
        <>
          {!_isEmpty(badgeVal) && discountedPrice !== finalPrice && discountedPrice > 0 && (
            <div className="product-tile__badge">
              <p className="product-tile__offer">{texts.sale}</p>
            </div>
          )}
          <div
            className="product-tile__badge"
            style={!_isEmpty(badgeVal) && discountedPrice !== finalPrice && discountedPrice > 0 ? { left: "85px" } : {}}
          >
            {!_isEmpty(badgeTxt) && (
              <p className="product-tile__new">{badgeTxt}</p>
            )}
          </div>
        </>
        ) : (
          <div className="product-tile__badge">
            {_isEmpty(badgeVal) && !_isEmpty(badgeTxt) && (
              <p className="product-tile__new">{badgeTxt}</p>
            )}
            {!_isEmpty(badgeVal) && (
              <p className="product-tile__offer">{texts.sale}</p>
            )}
          </div>
        )}
      <div
        role="link"
        id={props.id}
        className="product-tile__wrapper__card"
        aria-label={productName}
        tabIndex="0"
        onFocus={(e) => e.stopPropagation()}
        onClick={redirectPDP}
      >
        <div
          className={cx({ "product-tile": true, "product-tile--lite": lite })}
        >
          <a
            tabIndex="-1"
            href={`${tileUrl}${
              tileUrl?.includes("?type=part") ? "&" : "?"
            }skuId=${
              selSku !== ""
                ? typeof selSku === "string"
                  ? selSku
                  : selSku?.sku
                : sku
            }`}
            onClick={debounce(productTileClickHandler, 500)}
          >
            <div className="product-tile__image wow animate__fadeInUp">
              <div>
                {images.map((id, i) => {
                  const imgUrl = getPresetUrl(
                    width,
                    rowCols,
                    swatchUrl,
                    id,
                    presetConf, 
                    i
                  );
                  return (
                    <img
                      role="presentation"
                      key={i}
                      src={imgUrl ?? ""}
                      className={
                        images.length > 1 && i === 0
                          ? "product-tile__image--hover"
                          : "product-tile__image-tile"
                      }
                      alt={data.metaTitle_s}
                      onError={(e) => onImageError(e, imgUrl ? imgUrl : "")}
                    />
                  );
                })}
              </div>
            </div>
          </a>
          <div className="product-list-tile__details wow animate__fadeInUp">
            <div className="product-list-tile__details product-list-tile__compare-main">
              <div onClick={debounce(productTileClickHandler, 500)}>
                <div
                  aria-label={`${colors.length}${texts.colorsLinkAria}`}
                  className="product-list-tile__colors"
                >
                  {colors.length > 0 && (
                    <div className="product-list-tile__colors--swatch">
                      {colors?.slice(0, colorCount).map((color, i) => (
                        <a
                          data-gbh-data-layer={getEventInfo(null, color)}
                          data-gbh-data-layer-custom={getProductInfo(color)}
                          key={i}
                          onClick={(e) =>
                            onSelectedColor(
                              e,
                              color.sku,
                              color.customerFacingSKU
                            )
                          }
                          role="link"
                          tabIndex="0"
                          aria-label={`${color.name} color`}
                          aria-hidden={onHover ? false : true}
                          rel="noreferrer"
                          title={color.name}
                          className={cx({
                            "product-list-tile__color-swatch gbh-data-layer": true,
                            "selected-swatch": selectedColor.sku === color.sku,
                          })}
                          onMouseEnter={() => {
                            setHoverColor({
                              sku: color.sku,
                              customerFacingSKU: color.customerFacingSKU,
                            });
                          }}
                          onMouseLeave={() =>
                            setHoverColor(
                              Object.keys(selectedColor).length > 0
                                ? selectedColor
                                : { sku: firstSwatchColor }
                            )
                          }
                          onFocus={() => {
                            setHoverColor({
                              sku: color.sku,
                              customerFacingSKU: color.customerFacingSKU,
                            });
                          }}
                          onKeyDown={(e) => checkColorFocus(e, colors, i)}
                        >
                          <img
                            role="presentation"
                            src={`${general?.swatchUrl?.toString().includes("PAWEB")?general?.swatchUrl.replace("PAWEB", general?.scene7AccountName):general?.swatchUrl}${color.swatch}${
                              width <= 768
                                ? "?$PLPSwatch_Mobile50$"
                                : "?$PLPSwatch_Desk100$"
                            }`}
                            alt={color.name}
                            onError={(e) => colorSwatchDefaultImg(e)}
                          />
                        </a>
                      ))}
                      <span>
                        {colors.length > colorCount &&
                          "+" + (colors.length - colorCount)}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={cx({
                    "product-list-tile__info": true,
                    "mt-0": !colors.length,
                  })}
                >
                  {productName && (
                    <h3
                      aria-label={productName}
                      className="product-list-tile__title"
                    >
                      {productName}
                    </h3>
                  )}
                </div>
                <div>
                  {!isSample && finalPrice > 0 && (
                    <p
                      aria-label={`${texts.salePriceAria}${currencySign}${
                        discountedPrice > 0 ? discountedPrice : finalPrice ?? 0
                      } 
                                ${
                                  isGrouped &&
                                  (discountedPrice > 0
                                    ? discountedPrice !== discountedMaxPrice
                                    : finalPrice !== maxPrice)
                                    ? " - " +
                                      currencySign +
                                      (discountedMaxPrice > 0
                                        ? discountedMaxPrice
                                        : maxPrice)
                                    : ""
                                }`}
                      className={`product-list-tile__price ${
                        !isGrouped && "product-list-tile_paddingtop"
                      }`}
                    >
                      <NumberFormat
                        value={
                          discountedPrice > 0
                            ? discountedPrice
                            : finalPrice ?? 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currencySign}
                        suffix={priceTag}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      {isGrouped &&
                        (discountedPrice > 0
                          ? discountedPrice !== discountedMaxPrice
                          : finalPrice !== maxPrice) && (
                          <NumberFormat
                            value={
                              discountedMaxPrice > 0
                                ? discountedMaxPrice
                                : maxPrice ?? 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`- ${currencySign}`}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        )}
                      {discountedPrice !== finalPrice &&
                        discountedPrice > 0 && (
                          <span
                            aria-label={`${
                              texts.originalPriceAria
                            }${currencySign}${finalPrice ?? 0}${
                              isGrouped && finalPrice !== maxPrice
                                ? " - " + currencySign + discountedMaxPrice
                                : ""
                            }`}
                            className={cx({
                              "product-list-tile__discount-price": true,
                              "pl-0 d-block":
                                isGrouped && width <= 480 && rowCols === 2,
                            })}
                          >
                            <NumberFormat
                              value={finalPrice ?? 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currencySign}
                              suffix={priceTag}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                            {isGrouped && finalPrice !== maxPrice && (
                              <NumberFormat
                                value={maxPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={`- ${currencySign}`}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            )}
                          </span>
                        )}
                    </p>
                  )}
                </div>
              </div>
              <div
                className="product-list-tile__right-controls"
                onClick={debounce(productTileClickHandler, 500)}
              >
                {!isGrouped && !hideCompare && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className={`product-list-tile__compare ${
                      isTablet ? "product-list-tile__compare-tablet" : ""
                    }`}
                  >
                    <Checkbox
                      id={`kf-checkbox-${
                        selSku !== "" &&
                        selSku !== undefined &&
                        (typeof selSku === "string" ? selSku : selSku?.sku)
                      }`}
                      index={props.index}
                      value={texts.addToCompare}
                      aria={`${description} ${texts.addToCompare}`}
                      checked={isChecked}
                      onChange={(e) =>
                        handleCompare(
                          e,
                          selSku !== "" &&
                            selSku !== undefined &&
                            (typeof selSku === "string" ? selSku : selSku?.sku)
                        )
                      }
                      disabled={isEnable}
                    />
                    {errorMessage !== "" && <Tooltip message={errorMessage} />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.favPage && (
        <div className="product-tile__share">
          <button
            id={`kf-add-to-fav-${props.index}`}
            className="product-tile__share-icon"
            tabIndex="0"
            aria-label={t("kf.plp.label.wishlist", {
              product: productName,
            })}
            aria-hidden={width > 1024 ? (onHover ? false : true) : false}
            onClick={handleFavorite}
          >
            <img
              role="presentation"
              src={isFavorite ? filledWishIcon?.src : wishIcon?.src}
              alt={texts.wishlist}
            />
          </button>
          <button
            className="product-tile__share-icon-plp gbh-data-layer"
            tabIndex="0"
            aria-label={t("kf.plp.label.share.aria", {
              product: productName,
            })}
            aria-hidden={width > 1024 ? (onHover ? false : true) : false}
            data-gbh-data-layer={getEventInfo("share")}
            data-gbh-data-layer-custom={getProductInfo()}
            onClick={handleShare}
          >
            <img role="presentation" src={shareIcon?.src} alt={texts.share} />
          </button>
        </div>
      )}
      {isPartsDiagramLinkVisible && (
        <div className="product-tile__wrapper__card__parts">
          <a
            href="#"
            className="product-parts__viewer"
            onClick={handleOnClickPartsDiagramLink}
          >
            {serviceParts}
          </a>
        </div>
      )}
    </div>
  );
}

export default ProductTile;
